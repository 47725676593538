export enum TimeControlSummaryByWorkCenterAlertType {
    OK = 1,
    HasIncidences = 2,
    SupervisedByTimeControlResponsible = 3,
}

export enum TimeControlSummaryByEmployeeStatusType {
    InProgress = 0,
    Validated = 1,
    NotValidated = 2,
}

export enum CalendarDayType {
    WorkingDay = 1,
    NonWorkingDay = 13,
    PublicHoliday = 14,
    MedicalSickLeave = 2,
    Holiday = 3,
    PaternityLeave = 6,
    MarriagePermit = 7,
    ClockInWithoutApp = 9,
    WithoutClockIn = 12,
    GuardInPublicHolidayDay = 15,
    FamilyHospitalization = 16,
    GuardInNonWorkingDay = 17,
    SigningOnSheetOfPaper = 18,
    HolidayPreviousYear = 11,
    WorkLeave = 19,
    FamilyDeath = 20,
    WithoutClockOut = 21
}

export enum EmployeeStatus {
    Active = 0,
    InactiveLast30Days = 1,
    Inactive = 2,
}

export enum IncidenceType {
    WithoutClockIn = 0,
    WithoutClockOut = 1
}